import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto otp verify`}</strong>{` -- verify a one-time password`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto otp verify [--secret=<file>]
[--period=<seconds>] [--skew=<num>] [--length=<size>]
[--alg=<alg>] [*-time=<time|duration>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto otp verify`}</strong>{` does TOTP and HTOP`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--secret`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing TOTP secret.`}</p>
    <p><strong parentName="p">{`--period`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Number of seconds a TOTP hash is valid. Defaults to 30
seconds.`}</p>
    <p><strong parentName="p">{`--skew`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Periods before or after current time to allow. Defaults
to 0. Values greater than 1 require '--insecure' flag.`}</p>
    <p><strong parentName="p">{`--length`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`--digits`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Length of one-time passwords. Defaults to 6 digits.`}</p>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`--algorithm`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Algorithm to use for HMAC. Defaults to SHA1. Must be
one of: SHA1, SHA256, SHA512`}</p>
    <p><strong parentName="p">{`--time`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` to use for TOTP validation. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h". A `}<inlineCode parentName="p">{`duration`}</inlineCode>{` value is added to the current time. An empty
`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` defaults to "time.Now()".`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      